import React,{useRef} from 'react'
import "./burger.css"
import './Header.css'

function Burger(props) {
  return (
    <div onClick={props.handleClick}  
            className={`${props.clicked ? 'icon nav-icon-8 open' : 'icon nav-icon-8'}`}
    >
    
    <span ></span>
    <span ></span>
    <span ></span>


  </div>
  )
}

export default Burger
