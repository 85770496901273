/* components */
import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import "./Header.css";
import Burger from "./Burger";
import { Servicios } from "./templates/Servicios";
/* css */
import "./Header.css";
import "../FooterCss/Footer.css";
import "../MainCss/Main.css";
/* imgs */
import pantallaRota from "../../assets/sercicios/Pantallas-ntoebook.webp";
import bisagras from "../../assets/sercicios/bisagras_notebook.webp";
import teclados from "../../assets/sercicios/teclados_notebook.webp";
import Logo from "../../assets/svg/Logo";
import pago1 from "C:/Users/NITRO5/Desktop/Computecnica/computecnica/src/assets/pago1.svg";
import pago2 from "C:/Users/NITRO5/Desktop/Computecnica/computecnica/src/assets/pago2.png";
import pago3 from "C:/Users/NITRO5/Desktop/Computecnica/computecnica/src/assets/cash.jpg";
import winLateral from "C:/Users/NITRO5/Desktop/Computecnica/computecnica/src/assets/imgWinLateral.webp";
/* carrusel img */
import ubicacion1 from "../../assets/2024-1.jpg";
import ubicacion2 from "../../assets/2024-2.jpg";
import ubicacion3 from "../../assets/2024-3.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import Carou from "../Carrusel/Carou";

function Proyecto(props) {
  const [clicked, setCliked] = useState(false);
  const handleClick = () => {
    setCliked(!clicked);
  };
  const animation = "fade-right";
  const duration = "500";
  return (
    <>
      {/* -------------------------------------------------- Header */}
      <header
        className="a "
        data-aos={"fade-down"}
        data-aos-duration={1000}
        data-aos-delay={500}
      >
        <div className="header__logo--container">
          <Logo />
        </div>

        <nav
          onClick={handleClick}
          className={`header__content ${clicked ? "active" : "inactive"} `}
        >
          <ul>
            <li>
              <a href="">
                {" "}
                <HashLink to="#Pantallas">Pantallas Notebook</HashLink>
              </a>
            </li>
            <li>
              <a href="">
                {" "}
                <HashLink to="#Bisagras">Bisagras Notebook</HashLink>
              </a>
            </li>
            <li>
              <a href="">
                {" "}
                <HashLink to="#Discos">Discos SSD</HashLink>
              </a>
            </li>
            <li>
              {" "}
              <a href="#Cargadores" target="blank">
                Cargadores
              </a>{" "}
            </li>
            <li>
              <a href="">
                {" "}
                <HashLink to="#contacto">Contacto</HashLink>
              </a>
            </li>
            <li>
              {" "}
              <a href="#Mantencion" target="blank">
                Mantención Notebook
              </a>{" "}
            </li>
          </ul>
        </nav>

        <Burger clicked={clicked} handleClick={handleClick} />
      </header>
      {/* -------------------------------------------------- Main */}
      <main className={`${clicked ? "blurOn" : ""} `}>
        <section
          className="intro_container "
          data-aos={"zoom-out"}
          data-aos-duration={2000}
          data-aos-delay={100}
        >
          <div className="title_container">
            <h1>
              Somos una empresa con soluciones integrales para sus necesidades{" "}
              <br />
              En el area de:
            </h1>
            <h2>Computadores, Notebook y MacBook</h2>
          </div>
        </section>
        {/* -------------------------------------------------- New modules tailwind */}
        <section className="">
          <Servicios />
        </section>
      </main>

      {/*-------------------------------------------------------- Footer */}
      <footer cl id="contacto" className={`${clicked ? "blurOn" : ""} `}>
        <section
          className="ubicacion max-w-screen-2xl my-0 mx-auto px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16"
          data-aos={animation}
          data-aos-duration={duration}
        >
          <article
            className="ubicacion_footer-left"
            data-aos={animation}
            data-aos-duration={duration}
          >
            <Carou src1={ubicacion1} src2={ubicacion2} src3={ubicacion3} />
          </article>

          <article
            className="ubicacion_footer-right"
            data-aos={animation}
            data-aos-duration={duration}
          >
            <div className="wha-ema">
              <h5 className="experiencia">20 años de experiencia</h5>
              <h5>Trabajamos con garantía</h5>
              <h5>Emitimos Factura</h5>
              <h5>
                Escríbenos por WhatsApp
                <a
                  href="https://api.whatsapp.com/send?phone=56995102574&text=Hola%20quisiera%20preguntarles%20si%20me%20pueden%20ayudar%20con:"
                  target={"blank"}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                  </svg>
                </a>
              </h5>
            </div>

            <p>
              Visitanos en:
              <br />
              Avenida providencia 2237
              <br />Ó entrar por Paseo las Palmas 2230 <br /> Local C-22 Piso 1
              caracol Providencia, Región Metropolitana{" "}
            </p>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.064677375887!2d-70.61116118500927!3d-33.42155810341562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf689c01c1d5%3A0x8834c0d477a262cb!2sAv.%20Providencia%202237%2C%207510089%20Providencia%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses-419!2sus!4v1679950298859!5m2!1ses-419!2sus"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </article>
        </section>
        <section
          className="conclucion"
          data-aos={animation}
          data-aos-duration={duration}
        >
          <div className="footer_left">
            <Logo className="computecnica" />
            <p>Avenida providencia 2237 local C-22 </p>
            <p>También puedes entrar por:</p>
            <p>Paseo las Palmas 2230 local C-22 </p>
            <p>Piso 1 caracol</p>

            <p>Región Metropolitana</p>
            <p>Providencia</p>
            <p> Metro los Leones</p>
            <p>Lunes a Viernes </p>
            <p>10:00 hrs a 19:30 hrs</p>
            <p>Sábados </p>
            <p>Sábados de 11:00 a 15:00 hrs </p>
            <p className="footer_phone">
              {" "}
              Teléfono: <a href="tel:+56995102574">+56995102574</a>
            </p>
          </div>

          <div
            className="footer_right"
            data-aos={animation}
            data-aos-duration={duration}
          >
            <p>Síguenos en redes sociales</p>
            <div className="social_container">
              <a
                href="mailto:contacto@computecnica.cl"
                target={"_blank"}
                rel="noreferrer"
              >
                <span className="material-symbols-outlined">mail</span>
              </a>

              <a
                href="https://www.facebook.com/profile.php?id=100089560880847"
                target={"_blank"}
                rel="noreferrer"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                </svg>{" "}
              </a>

              <a
                href="https://www.instagram.com/computecnica_stgo/"
                target={"_blank"}
                rel="noreferrer"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                </svg>{" "}
              </a>

              <a
                href="https://www.tiktok.com/@computecnica_stgo?lang=es"
                target={"_blank"}
                rel="noreferrer"
              >
                {" "}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
                </svg>
              </a>
            </div>

            <h4>Métodos de pago</h4>
            <img className="pago onepay" src={pago2} alt="transferencia" />
            <img className="pago onepay" src={pago3} alt="efectivo" />
            <img className="pago compraqui" src={pago1} alt="compraqui" />
            <p>Trabajos garantizados</p>
            <p>Boleta o factura</p>
          </div>
        </section>
      </footer>
      <a
        href="https://api.whatsapp.com/send?phone=56995102574&text=Hola%20quisiera%20preguntarles%20si%20me%20pueden%20ayudar%20con:"
        target={"blank"}
      >
        <span className="material-symbols-outlined callFixed ws">chat</span>{" "}
      </a>
      <a href="tel:+56995102574">
        <span className="material-symbols-outlined callFixed">add_call</span>{" "}
      </a>
    </>
  );
}

export default Proyecto;
