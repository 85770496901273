import React from "react";
import pantallaRota from "../../../assets/sercicios/Pantallas-ntoebook.webp";
import bisagras from "../../../assets/sercicios/bisagras_notebook.webp";
import pastaTermica from "../../../assets/sercicios/Cambio_Pasta_Térmica.webp";
import cargadores from "../../../assets/sercicios/cargadores_notebook.webp";
import discosSolidos from "../../../assets/sercicios/discos-sólidos.webp";
import teclados from "../../../assets/sercicios/teclados_notebook.webp";
import usb from "../../../assets/sercicios/USB.webp";
import pastaTermica2 from "../../../assets/sercicios/Pasta_Térmica.webp";
import mantencionGamer from "../../../assets/sercicios/mantención_gamer.webp";
import mantencionHardware from "../../../assets/sercicios/mantención_hardware.webp";
import mantencionNotebook from "../../../assets/sercicios/mantenimiento-note.webp";
import mantencionPc from "../../../assets/sercicios/mantenimiento-pc.webp";
import discosSSD from "../../../assets/sercicios/discos-ssd.webp";
import "../../../index.css";

export const Servicios = ({ imagen }) => {
  return (
    <section className="bg-gray-900 text-white servicios">
      <div className="max-w-screen-2xl my-0 mx-auto px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 ">
        <div className="max-w-xl text-center mx-auto">
          <h2 className="text-3xl font-bold sm:text-4xl">
            Soluciones personalizadas a su medida
          </h2>

          <p className="mt-4 text-gray-300 font p">
            Profesionales especializados <br /> Contamos con una ubicación
            privilegiada en el corazón de Providencia
          </p>
        </div>

        <div className="mt-8 grid grid-cols-1 gap-8 md:mt-16 md:grid-cols-2 md:gap-12 lg:grid-cols-3">
          <div className="flex flex-col items-start gap-4">
            <span id="Pantallas" className="shrink-0 rounded-lg bg-gray-800">
              <img src={pantallaRota} alt="Pantallas Notebook" />
            </span>

            <div>
              <h2 className="text-lg font-bold">Pantallas Notebook</h2>

              <p className="mt-1 text-sm text-gray-300">
                Todas las marcas <br /> Garantía de 3 meses
              </p>
            </div>
          </div>

          <div className="flex flex-col items-start gap-4">
            <span id="Bisagras" className="shrink-0 rounded-lg bg-gray-800">
              <img src={bisagras} alt="Bisagras Notebook" />
            </span>

            <div>
              <h2 className="text-lg font-bold">Bisagras Notebook</h2>

              <p className="mt-1 text-sm text-gray-300">
                Lista en 4 horas <br /> Garantía de 3 meses
              </p>
            </div>
          </div>

          <div className="flex flex-col items-start gap-4">
            <span className="shrink-0 rounded-lg bg-gray-800">
              <img src={teclados} alt="Teclados Notebook" />
            </span>

            <div>
              <h2 className="text-lg font-bold">Teclados Notebook</h2>

              <p className="mt-1 text-sm text-gray-300">
                Todas las marcas <br /> Garantía de 3 meses
              </p>
            </div>
          </div>

          <div className="flex flex-col items-start gap-4">
            <span className="shrink-0 rounded-lg bg-gray-800">
              <img src={pastaTermica} alt="Cambio Pasta Térmica" />
            </span>

            <div>
              <h2 className="text-lg font-bold">Cambio Pasta Térmica</h2>

              <p className="mt-1 text-sm text-gray-300">
                Procesadores <br /> Tarjetas de video
              </p>
            </div>
          </div>

          <div className="flex flex-col items-start gap-4">
            <span id="Cargadores" className="shrink-0 rounded-lg bg-gray-800">
              <img src={cargadores} alt="Cargadores Notebook" />
            </span>

            <div>
              <h2 className="text-lg font-bold">Cargadores Notebook</h2>

              <p className="mt-1 text-sm text-gray-300">
                Todas las marcas <br /> Garantía de 3 meses
              </p>
            </div>
          </div>

          <div className="flex flex-col items-start gap-4">
            <span id="Discos" className="shrink-0 rounded-lg bg-gray-800">
              <img src={discosSolidos} alt="Discos Sólidos" />
            </span>

            <div>
              <h2 className="text-lg font-bold">Discos Sólidos</h2>

              <p className="mt-1 text-sm text-gray-300">
                Instalados <br /> Garantía de 6 meses
              </p>
            </div>
          </div>

          <div className="flex flex-col items-start gap-4">
            <span className="shrink-0 rounded-lg bg-gray-800">
              <img src={discosSSD} alt="Discos SSD" />
            </span>

            <div>
              <h2 className="text-lg font-bold">Discos SSD</h2>

              <p className="mt-1 text-sm text-gray-300">
                Instalados <br /> Garantía de 6 meses
              </p>
            </div>
          </div>

          <div className="flex flex-col items-start gap-4">
            <span id="Mantencion" className="shrink-0 rounded-lg bg-gray-800">
              <img src={mantencionNotebook} alt="Mantención Notebook" />
            </span>

            <div>
              <h2 className="text-lg font-bold">Mantención Notebook</h2>

              <p className="mt-1 text-sm text-gray-300">
                Lista en una hora <br /> Lubricación <br /> Pasta Térmica
              </p>
            </div>
          </div>

          <div className="flex flex-col items-start gap-4">
            <span className="shrink-0 rounded-lg bg-gray-800">
              <img src={mantencionGamer} alt="Mantención Notebook Gamer" />
            </span>

            <div>
              <h2 className="text-lg font-bold">Mantención Notebook Gamer</h2>

              <p className="mt-1 text-sm text-gray-300">
                Lista en una hora <br /> Lubricación <br /> Pasta Térmica
              </p>
            </div>
          </div>

          <div className="flex flex-col items-start gap-4">
            <span className="shrink-0 rounded-lg bg-gray-800">
              <img src={mantencionPc} alt="Mantenimiento PC" />
            </span>

            <div>
              <h2 className="text-lg font-bold">Mantenimiento PC</h2>

              <p className="mt-1 text-sm text-gray-300">
                Lista en una hora <br /> Lubricación <br /> Pasta Térmica
              </p>
            </div>
          </div>

          <div className="flex flex-col items-start gap-4">
            <span className="shrink-0 rounded-lg bg-gray-800">
              <img src={mantencionHardware} alt="Mantención Hardware" />
            </span>

            <div>
              <h2 className="text-lg font-bold">Mantención Hardware</h2>

              <p className="mt-1 text-sm text-gray-300">
                Computadores Gamer <br /> Computadores de escritorio
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start gap-4">
            <span className="shrink-0 rounded-lg bg-gray-800">
              <img src={usb} alt="Puertos USB" />
            </span>

            <div>
              <h2 className="text-lg font-bold">Puertos USB</h2>

              <p className="mt-1 text-sm text-gray-300">
                Reparación USB <br /> Todas las marcas
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
