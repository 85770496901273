import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";

const Carou = (props) => {
  return (
    <Carousel className={props.area2}>
      <Carousel.Item>
        <img
          className="d-block w-100 img joda "
          src={props.src1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Conoce nuestra oficina </h3>
          <a href="tel:+56995102574">
            <span className="material-symbols-outlined">add_call</span>{" "}
          </a>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100 img joda"
          src={props.src2}
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3>
            <a href="tel:+56995102574">
              Llamanos al: <br />
              +56995102574
              <span className="material-symbols-outlined">add_call</span>{" "}
            </a>
          </h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 img joda"
          src={props.src3}
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3>
            <a href="tel:+56995102574">
              <p>Rápido diagnóstico</p>
              <span className="material-symbols-outlined">add_call</span>{" "}
            </a>
          </h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Carou;
